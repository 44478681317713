import React from "react";

import { Typography } from "@/uikit/Typography";
import { Svg } from "@/uikit/Svg";

import { descriptionStyle, featureTitleStyle, titleStyle } from "./remote.css";

import { FlexContainer } from "@/uikit/container";
import { RemoteFeatureSchema } from "@/schema";

export const RemoteFeature = (props: RemoteFeatureSchema) => {
  return (
    <div>
      {props.title && (
        <FlexContainer className={titleStyle}>
          {props.icon && <Svg asset={props.icon} />}
          <Typography className={featureTitleStyle}>{props.title}</Typography>
        </FlexContainer>
      )}
      <Typography settings={{ tag: "p" }} className={descriptionStyle}>
        {props.description}
      </Typography>
    </div>
  );
};
