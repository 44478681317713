import React, { useState } from "react";

import { Block } from "@/blocks/base";
import { BlockContainer } from "@/blocks/base/block-container";
import { Typography } from "@/uikit/Typography";

import {
  blockStyle,
  commonTitleStyle,
  contentContainerStyle,
  contentWrapperStyle,
  fullWidthTabStyle,
  imgBorderStyle,
  imgWrapperStyle,
  listItemStyle,
  remoteWrapperStyle,
  tabNameStyle,
  tabNameWrapperRecipe,
  tabNameWrapperStyle,
  tabsNamesWrapperStyle,
  titleStyle,
  verticalAlignStyle,
} from "./tabs.css";

import { renderActions } from "../schema-mappers";
import { FlexContainer } from "@/uikit/container";
import { TabSchema } from "@/schema";
import { clsx } from "@/uikit/utils";
import { RemoteImage } from "@/uikit/remote-image";
import { RemoteFeature } from "../Remote";

const renderTabs = (
  tabs: TabSchema[],
  activeTab: number,
  setActiveTab: (index: number) => void,
  tabsStyle: "underlined" | "round",
  tabsPosition: "left" | "center",
) => {
  return (
    <div>
      <FlexContainer
        className={clsx(
          tabsNamesWrapperStyle({ style: tabsStyle, position: tabsPosition }),
          tabs.length === 3 ? verticalAlignStyle : "",
        )}
      >
        {tabs.map(({ name }, i) => (
          <div
            className={clsx(
              tabNameWrapperRecipe({ style: tabsStyle }),
              tabNameWrapperStyle,
              tabs.length === 3 ? fullWidthTabStyle : "", // todo needs to be implemented in other way
            )}
            key={i}
            data-state={activeTab === i}
            onClick={() => setActiveTab(i)}
          >
            <Typography settings={{ tag: "p" }} className={tabNameStyle({ style: tabsStyle })}>
              {name}
            </Typography>
          </div>
        ))}
      </FlexContainer>
    </div>
  );
};

const renderTitle = (title: string, titlePosition: "left" | "center") => {
  if (!title) return null;
  return <Typography.subtitle className={titleStyle({ position: titlePosition })}>{title}</Typography.subtitle>;
};

const TabsBlock = Block("tabs", (props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const { commonTitle, tabs, tabsStyle, tabsPosition, titlePosition, contentAlignment, imageBorder } = props;

  return (
    <BlockContainer defaultTheme="light" settings={props.settings}>
      <ul>
        {tabs.map((activeTab, i) => (
          <li key={i} className={listItemStyle({ visible: activeTabIndex === i })}>
            <div className={blockStyle({ alignment: contentAlignment })}>
              <Typography.subtitle className={commonTitleStyle}>{commonTitle}</Typography.subtitle>
              {tabsPosition === "center" &&
                renderTabs(tabs, activeTabIndex, setActiveTabIndex, tabsStyle, tabsPosition)}
              {titlePosition === "center" && renderTitle(activeTab.title || "", titlePosition)}
              <FlexContainer
                className={contentContainerStyle({ marginTop: contentAlignment === "row" })}
                justifyContent="center"
                alignItems="stretch"
                direction={contentAlignment}
              >
                <FlexContainer
                  direction="column"
                  alignItems="start"
                  justifyContent="start"
                  className={contentWrapperStyle({ alignment: contentAlignment })}
                >
                  {tabsPosition === "left" &&
                    renderTabs(tabs, activeTabIndex, setActiveTabIndex, tabsStyle, tabsPosition)}
                  {titlePosition === "left" && renderTitle(activeTab.title || "", titlePosition)}
                  {activeTab.actions && renderActions(activeTab.actions)}
                </FlexContainer>
                {contentAlignment === "column" && <RemoteFeature {...activeTab.body} />}
                <div
                  className={clsx(imgWrapperStyle({ alignment: contentAlignment }), imageBorder ? imgBorderStyle : "")}
                >
                  <RemoteImage
                    image={activeTab.heroImage}
                    sizes={{
                      mobile: "calc(100vw - 20px)",
                      tablet: "calc(50vw - 20px)",
                      desktop: "calc(30vw - 20px)",
                    }}
                    alt={activeTab.title}
                    quality={100}
                  />
                </div>
                {contentAlignment === "row" && (
                  <div className={remoteWrapperStyle}>
                    <RemoteFeature {...activeTab.body} />
                  </div>
                )}
              </FlexContainer>
            </div>
          </li>
        ))}
      </ul>
    </BlockContainer>
  );
});

export default TabsBlock;
