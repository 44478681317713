import "../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.19.5_webpack@5.91.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fstyles%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA23S227CMAwA0He%2BwlKeQbk5Tc0Tleh%2F9JLuBoNdgU3798mdJjlR46cepXbsZHM6nG6Xs4bvFcB6%2FfdlCJSNNjqzFWoJ1DQvqY5ADYlDqidQ%2B2bftHupSKB84xu0UgPnrYOpeqkVgWqbaGqUGrmaHvSQaU2g9LykdpzXT3HK8vYEytWu85XUgdU79FnHI4GqXOxrLzXxdIw1tpY6LU7njjVxSL3n3nbtrm2lPizO4XFxDk9zNeyrrIsDgRoHDqlHApUwYcpO9swZkEPq6V87qWeeehjCmE3nhUDhiClk1V65WsCuyva%2BzZPsEEep74tz%2BOC7cM757DY%2FF9%2FOZfGdXQn0%2BSrlRhBz%2BSIwNiejCUwozBDYIpmxBNYX5ghsUcJ4Al%2F%2BiwRY1g0ERpcbqxkzinyWnGqCWJbtuLVQHrAn0BuXjtvVzy9J5bov9QMAAA%3D%3D%22%7D"
import "../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.19.5_webpack@5.91.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fblocks%2FTabs%2Ftabs.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA41V227jIBB971cgrVZtpVLZThwnVLva%2F1jtAw7EpiXgBZw4XeXfV%2BBLgDhtH2JZeObM4czMyXNVlmlnUvDvDoA97uCREVMjUGRJ0724M1UxgUACcGvky9357rlPWbiUd8gEoR0CqQ2WreFMUKjNiVMEhBTUHpdSEaqgwoS1GoHNxkJfgH4TbDDUBhv6496olt7%2FibCTm%2BATSo5qeaDKJW5bpaVCoJFMGKq8qJX7vpPCQM3eKQLjNRtMCBMVLKUxco%2FAsj%2F%2BuOZyjvlPcF3rSFlVGwSKJPH0GGtlTQe05IyAb4kNmPCLmG2aB2wRWDfddIUPKoXKA1Di7VulZCsI3EpupTpg9QCh5PJ0bHaPNsYoLDQzTAoEXBBInjMNKNYUytb4NH3pr%2BHY45clK25h9JQ%2Bpt36ddYOqcINAoskGLeN%2BzKM%2BY4ZuJXCUGHmhv1rSt0Q2RX321m6yq%2BtNmx3GssisKXRkNKYe7CZeRLep4qHZLnuc9zg1sM85KsgqXZJhnYGYs4qcWFxEcHWjpf%2BdfAJGzHN78IfwJDDXIUJ7M0DCzQP9VwHzLnfvrwY9WFi1GcZ6bP3KRvZ2Ig%2BaUhYRQMibyRMAU1slnmW%2B2bZJ6VZmPXXZR2YZiXjzJwQqBkhVNi8sUuJR8u9N3JcQVxqyVtD%2B85qc8OS%2Bi28pCnKsWEHl%2BbXdu%2Bc%2BsVH%2BYf6Uee1A%2B5b4%2BxxsKxjzcycyafZ4KAHqnZcHv3rlrKDusbEntohS%2B0js5j2RVUlfsjyJ%2BB%2BxZP1Hbfav%2FaUMAwePOXTJFs03aPjFuxDOI0Dl%2FM8SLEqIozFgDG%2FIOdL4Oq62OjRXlQxRIW2nfZxs8nR9o4W72GuB8zr9fGCNiM9Tjt4VNZR7PPlckiYotthVOSx%2F%2BCcZz0SuXZA98%2FQYDWYplePDPWm7iTfowj6acRM%2FxbZ1fXr6Pp2A%2BcUeL2NFmm8vNaYz5MN7GbmBvtP7yj8vngt2Ere7kUU3NzsdGg4q%2BkC5%2F%2F2PSWv1gkAAA%3D%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var blockStyle = _7a468({defaultClassName:'',variantClassNames:{alignment:{row:'gbb1xt0',column:'gbb1xt1'}},defaultVariants:{},compoundVariants:[]});
export var commonTitleStyle = 'gbb1xtj';
export var contentContainerStyle = _7a468({defaultClassName:'gbb1xtn',variantClassNames:{marginTop:{true:'gbb1xto'}},defaultVariants:{},compoundVariants:[]});
export var contentWrapperStyle = _7a468({defaultClassName:'',variantClassNames:{alignment:{row:'gbb1xte',column:'gbb1xtf'}},defaultVariants:{},compoundVariants:[]});
export var fullWidthTabStyle = 'gbb1xtd';
export var imgBorderStyle = 'gbb1xts';
export var imgWrapperStyle = _7a468({defaultClassName:'gbb1xtk',variantClassNames:{alignment:{row:'gbb1xtl',column:'gbb1xtm'}},defaultVariants:{},compoundVariants:[]});
export var listItemStyle = _7a468({defaultClassName:'gbb1xtq',variantClassNames:{visible:{true:'gbb1xtr'}},defaultVariants:{},compoundVariants:[]});
export var remoteWrapperStyle = 'gbb1xtp';
export var tabNameStyle = _7a468({defaultClassName:'gbb1xt5',variantClassNames:{style:{underlined:'gbb1xt6',round:'gbb1xt7'}},defaultVariants:{},compoundVariants:[]});
export var tabNameWrapperRecipe = _7a468({defaultClassName:'',variantClassNames:{style:{underlined:'gbb1xt2',round:'gbb1xt3'}},defaultVariants:{},compoundVariants:[]});
export var tabNameWrapperStyle = 'gbb1xt4';
export var tabsNamesWrapperStyle = _7a468({defaultClassName:'',variantClassNames:{style:{underlined:'gbb1xt8',round:'gbb1xt9'},position:{left:'gbb1xta',center:'gbb1xtb'}},defaultVariants:{},compoundVariants:[]});
export var titleStyle = _7a468({defaultClassName:'gbb1xtg',variantClassNames:{position:{center:'gbb1xth',left:'gbb1xti'}},defaultVariants:{},compoundVariants:[]});
export var verticalAlignStyle = 'gbb1xtc';